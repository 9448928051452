<template>
    <!--项目管理界面-->
    <div class="base-container">
        <div class="nav_bar">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" active-text-color="#185BCE" @select="handleSelect">
                <el-menu-item index="1" v-if="utils.isDisplay('constructionAnalysis:view')">建设方分析</el-menu-item>
                <el-menu-item index="2" v-if="utils.isDisplay('executorAnalysis:view')">施工方分析</el-menu-item>
                <el-submenu index="3" v-if="utils.isDisplay('project:view')">
                    <template slot="title">项目信息</template>
                    <el-menu-item index="3-1" v-if="utils.isDisplay('projectDetail:view')">项目画像</el-menu-item>
                    <el-menu-item index="3-2" v-if="utils.isDisplay('around:view')">周边情况</el-menu-item>
                    <el-menu-item index="3-3" v-if="utils.isDisplay('construction:view')">成本分析</el-menu-item>
                    <el-menu-item index="3-4" v-if="utils.isDisplay('region:view')">区域分析</el-menu-item>
                    <el-menu-item index="3-5" v-if="utils.isDisplay('executor:view')">税负预测</el-menu-item>
                </el-submenu>
                <el-submenu index="4" v-if="utils.isDisplay('riskAnalysis:view')">
                    <template slot="title">风险分析</template>
                    <el-menu-item index="4-4" v-if="utils.isDisplay('constructionRisk:view')">本项目建设方法律纠纷</el-menu-item>
                    <el-menu-item index="4-1" v-if="utils.isDisplay('executorRisk:view')">本项目与施工方纠纷</el-menu-item>
                    <el-menu-item index="4-2" v-if="utils.isDisplay('constructionOtherRisk:view')">建设方其他法律纠纷</el-menu-item>
                    <el-menu-item index="4-5" v-if="utils.isDisplay('executorOtherRisk:view')">施工方其他法律纠纷</el-menu-item>
                    <el-menu-item index="4-3" v-if="utils.isDisplay('newsRisk:view')">舆情风险</el-menu-item>
                </el-submenu>
            </el-menu>
        </div>
        <div class="prject-content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import {mapMutations} from "vuex";
import tabCase from '@/views/projectDetails/tabsContents/tabCase.vue'
import tabRule from '@/views/projectDetails/tabsContents/tabRule.vue'
import tabCaliber from '@/views/projectDetails/tabsContents/tabCaliber.vue'
import {getStore, setStore,getLocalStore} from "@/util/store";

const tabData = () => import ('@/views/projectDetails/tabsContents/tabData.vue')

export default {
    components: {
        tabData,//数据
        tabCase,//案例
        tabRule,//法规
        tabCaliber//口径
    },
    data() {
        return {
            activeName: "data",
            projectInfo: '',
            activeIndex: '1'
        }
    },
    computed: {
        //从VueX中获取保存的上一级点击的当前项目名称
        proNameID() {
            return this.$store.state.proNameID;
        }
    },
    created: function () {
        this.projectInfo = getStore('thisProject')
    },
    mounted() {
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL);
            // 给 popstate 绑定一个方法 监听页面刷新
            window.addEventListener('popstate', this.back, false);
        }
        let navItem = getStore('navitem');
        if(navItem=='analysisDetails'){
            this.activeIndex = '1';
            this.$router.push({path: "/analysisDetails"});
        }else if(navItem=='construction'){
            this.activeIndex = '2';
            this.$router.push({path: "/construction"});
        }else if(navItem=='constructionDetails'){
            //当前刷新页是施工信息时,不返回上一级
            this.activeIndex = '2';
            this.$router.push({path: "/constructionDetails"});
        }else if(navItem=='portraitProject'){
            this.activeIndex = '3-1';
            this.$router.push({path: "/portraitProject"});
        }else if(navItem=='flfxpg'){
            this.activeIndex = '4-1';
            this.$router.push({path: "/flfxpg"});
        }else if(navItem=='viewAll'){
             //当前刷新页是法律详情时,不返回上一级
            this.activeIndex = '4-1';
        }else if(navItem=='other'){
            this.activeIndex = '4-2';
        }else if(navItem=='publicSentiment'){
            this.activeIndex = '4-3';
        }else if(navItem=='tabBuild'){
            this.activeIndex = '4-4';
        }else if(navItem=='tabConstruction'){
            this.activeIndex = '4-5';
        }else if(navItem=='mapAround'){
            this.activeIndex = '3-2';
        }else if(navItem=='businessCost'){
            this.activeIndex = '3-3';
        }else if(navItem=='regional'){
            this.activeIndex = '3-4';
        }else if(navItem=='taxCase'){
            this.activeIndex = '3-5';
        }
        
    },
    // 页面销毁时，取消监听。否则其他vue路由页面也会被监听
    destroyed() {
        window.removeEventListener('popstate', this.back, false);
    },
    methods: {
        ...mapMutations(["reducePath", "addPath", "addMenuList", "reduceMenuList"]),
        back() {
            this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
            this.reducePath();//删除导航最后一个路由
            if(getStore("path").length==2){
                this.reducePath();//删除导航最后一个路由
            }
            var currentMenu = getStore("currentMenu");
            this.$router.push({path: currentMenu});
        },
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'projectSearch',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        handleSelect(key, keyPath) {
            if(getStore('currentMenu')=='/viewAll'){
                this.reducePath();//删除导航最后一个路由
                this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
            }
            if(getStore('currentMenu')=='/judicial'){
                this.reducePath();//删除导航最后一个路由
                this.reducePath();
                this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
                this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
            }
            this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
            this.reducePath();//删除导航最后一个路由
            if(key==1){
                this.getReport(2,'nav','buildingNav');
                this.addPath("建设方分析");
                this.addMenuList("/analysisDetails");
                setStore("currentMenu", "/analysisDetails");
                setStore("navitem", "analysisDetails");
                this.$router.push({path: "/analysisDetails"});
            }else if(key==2){
                this.getReport(2,'nav','constructionNav');
                this.addPath("施工方分析");
                this.addMenuList("/constructionDetails");
                setStore("currentMenu", "/constructionDetails");
                setStore("navitem", "constructionDetails");
                this.$router.push({path: "/constructionDetails"});
            }else if(key=='3-1'){
                this.getReport(2,'nav','portraitProject');
                this.addPath("项目画像");
                this.addMenuList("/portraitProject");
                setStore("currentMenu", "/portraitProject");
                setStore("navitem", "portraitProject");
                this.$router.push({path: "/portraitProject"});
            }else if(key=='3-2'){
                this.getReport(2,'nav','mapAround');
                this.addPath("周边情况");
                this.addMenuList("/mapAround");
                setStore("currentMenu", "/mapAround");
                setStore("navitem", "mapAround");
                this.$router.push({path: "/mapAround"});
            }else if(key=='3-3'){
                this.getReport(2,'nav','businessCost');
                this.addPath("成本分析");
                this.addMenuList("/businessCost");
                setStore("currentMenu", "/businessCost");
                setStore("navitem", "businessCost");
                this.$router.push({path: "/businessCost"});
            }else if(key=='3-4'){
                this.getReport(2,'nav','regionalAnalysis');
                this.addPath("区域分析");
                this.addMenuList("/regional");
                setStore("currentMenu", "/regional");
                setStore("navitem", "regional");
                this.$router.push({path: "/regional"});
            }else if(key=='3-5'){
                this.getReport(2,'nav','taxBurden');
                this.addPath("税负案例");
                this.addMenuList("/taxCase");
                setStore("currentMenu", "/taxCase");
                setStore("navitem", "taxCase");
                this.$router.push({path: "/taxCase"});
            }else if(key=='4-1'){
                this.getReport(2,'nav','constructionLaw');
                this.addPath("本项目与施工方纠纷");
                this.addMenuList("/flfxpg");
                setStore("currentMenu", "/flfxpg");
                setStore("navitem", "flfxpg");
                this.$router.push({path: "/flfxpg"});
            }else if(key=='4-2'){
                this.getReport(2,'nav','buildOtherLaw');
                this.addPath("建设方其他法律纠纷");
                this.addMenuList("/other");
                setStore("currentMenu", "/other");
                setStore("navitem", "other");
                this.$router.push({path: "/other"});
            }else if(key=='4-3'){
                this.getReport(2,'nav','publicOpinionRisk');
                this.addPath("舆情风险");
                this.addMenuList("/publicSentiment");
                setStore("currentMenu", "/publicSentiment");
                setStore("navitem", "publicSentiment");
                this.$router.push({path: "/publicSentiment"});
            }else if(key=='4-4'){
                this.getReport(2,'nav','buildLaw');
                this.addPath("本项目建设方法律纠纷");
                this.addMenuList("/tabBuild");
                setStore("currentMenu", "/tabBuild");
                setStore("navitem", "tabBuild");
                this.$router.push({path: "/tabBuild"});
            }else if(key=='4-5'){
                this.getReport(2,'nav','constructionOtherLaw');
                this.addPath("施工方其他法律纠纷");
                this.addMenuList("/tabConstruction");
                setStore("currentMenu", "/tabConstruction");
                setStore("navitem", "tabConstruction");
                this.$router.push({path: "/tabConstruction"});
            }
            
        },
        handleClick(tab, event) {
            // console.log(tab, event);
        },
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 0;
}

.base-container {
    .nav_bar{
        height: 52px;
        width: 100%;
        background-color: #fff;

        .el-menu--horizontal{
            width: 1200px;
            margin: 0 auto;
            .el-submenu{
                margin-right: 40px;
            }
            .el-menu-item{
                height: 52px;
                line-height: 52px;
                padding: 0;
                margin-right: 40px;
            }
            // .el-menu-item:hover{
            //     background: #185BCE !important;
            // }
            /deep/.el-submenu__title{
                height: 52px;
                line-height: 52px;
                padding: 0;
                
            }
        }
        .el-menu.el-menu--horizontal{
            border-bottom: none;
            
        }
    }
    .prject-content{
        width: 100%;
        height: calc(100% - 52px);
        padding-top: 20px;
        box-sizing: border-box;
    }

}
</style>