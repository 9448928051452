<template>
<div>caliber</div>
</template>

<script>
export default {
    name: "caliber"
}
</script>

<style scoped>

</style>