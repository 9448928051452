<template>
<div>rule</div>
</template>

<script>
export default {
    name: "rule"
}
</script>

<style scoped>

</style>